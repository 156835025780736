var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.value, width: "450" },
      on: {
        "click:outside": function($event) {
          return _vm.$emit("cancel")
        }
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Select Promotion Category")]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                { ref: "form", attrs: { "lazy-validation": "" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "Category Type",
                                  items: _vm.promoTypeOptions,
                                  "menu-props": { offsetY: true },
                                  "item-text": "name",
                                  "item-value": "value",
                                  "item-color": "action",
                                  "return-object": "",
                                  "hide-details": "auto",
                                  "error-messages": !_vm.selectedType.value
                                    ? [
                                        "Please select a type to view available categories"
                                      ]
                                    : [],
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.selectedType,
                                  callback: function($$v) {
                                    _vm.selectedType = $$v
                                  },
                                  expression: "selectedType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.selectedType.value
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "Promo Category",
                                      items: _vm.currentCategories,
                                      "menu-props": { offsetY: true },
                                      "item-text": "name",
                                      "item-value": "id",
                                      "item-color": "action",
                                      "hide-details": "",
                                      "return-object": "",
                                      clearable: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value: _vm.selectedCategory,
                                      callback: function($$v) {
                                        _vm.selectedCategory = $$v
                                      },
                                      expression: "selectedCategory"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "grey darken-1", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: !_vm.selectedCategory },
                  on: { click: _vm.confirm }
                },
                [_vm._v(" Confirm ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }