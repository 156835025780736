<template>
  <v-dialog
    :value="value"
    width="450"
    @click:outside="$emit('cancel')">
    <v-card>
      <v-card-title>Select Promotion Category</v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row>
              <v-col>
                <v-select
                  v-model="selectedType"
                  label="Category Type"
                  :items="promoTypeOptions"
                  :menu-props="{ offsetY: true }"
                  item-text="name"
                  item-value="value"
                  item-color="action"
                  return-object
                  hide-details="auto"
                  :error-messages="!selectedType.value ? ['Please select a type to view available categories'] : []"
                  outlined
                  dense>
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="selectedType.value">
              <v-col>
                <v-select
                  v-model="selectedCategory"
                  label="Promo Category"
                  :items="currentCategories"
                  :menu-props="{ offsetY: true }"
                  item-text="name"
                  item-value="id"
                  item-color="action"
                  hide-details
                  return-object
                  clearable
                  outlined
                  dense>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-1"
          text
          @click="$emit('cancel')">
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!selectedCategory"
          @click="confirm">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
export default {
  data () {
    return {
      selectedType: {
        value: '',
        constants: []
      },
      selectedCategory: null,
      complexTypes: ['BUY_X_SAVE_$Y', 'BUY_X_GET_X']
    }
  },
  name: 'PromoCategoryModal',
  mixins: [userAccess, utils],
  props: {
    value: Boolean,
    responsiblePartyId: String
  },
  watch: {
    'selectedType.value': {
      handler (newValue) {
        if (newValue && this.currentCategories.length === 1) {
          this.selectedCategory = this.currentCategories[0]
        } else {
          this.selectedCategory = null
        }
      }
    }
  },
  computed: {
    promoCategories () {
      return this.userPromoCategories.slice().sort((a, b) => a.name.localeCompare(b.name))
    },
    promoTypeOptions () {
      const typeOptions = [
        { name: 'Scan', value: 'scan', constants: [...this.scanTypes] },
        { name: 'Complex', value: 'complex', constants: [...this.complexTypes] },
        { name: 'Billback', value: 'billback', constants: [...this.billbackTypes] }
      ]
      return typeOptions.filter(type => {
        return type.constants.some(c => this.categoryTypeConstants.includes(c))
      })
    },
    categoryTypeConstants () {
      return this.getUniqueConstants(this.promoCategories)
    },
    currentCategories () {
      let currCategories = this.promoCategories.filter(category => {
        return this.selectedType.constants.includes(category?.promo_type?.constant)
      })
      if (this.tenant === 'pricechopper') {
        currCategories = this.sortByKey(currCategories, 'name')
        if (this.selectedType.value === 'billback') {
          const dsdOffIndex = currCategories.findIndex(cat => cat.name === 'DSD Off Invoice')
          if (dsdOffIndex > -1) currCategories.unshift(currCategories.splice(dsdOffIndex, 1)[0])
          const sevenDayIndex = currCategories.findIndex(cat => cat.name === '7 Day Ad - Billback')
          if (sevenDayIndex > -1) currCategories.unshift(currCategories.splice(sevenDayIndex, 1)[0])
          const specialFourWeekIndex = currCategories.findIndex(cat => cat.name === 'Special 4 Week')
          if (specialFourWeekIndex > -1) currCategories.push(currCategories.splice(specialFourWeekIndex, 1)[0])
        }
      }
      return currCategories
    },
    scanTypes () {
      let scanCategories = this.promoCategories.filter(category => {
        if (category?.scan_promotion === true && !category?.billback_promotion) {
          return !this.complexTypes.includes(category?.promo_type?.constant)
        }
      })
      return this.getUniqueConstants(scanCategories)
    },
    billbackTypes () {
      const billbackCategories = this.promoCategories.filter(cat => cat?.billback_promotion === true)
      return this.getUniqueConstants(billbackCategories)
    }
  },
  methods: {
    getUniqueConstants (categories) {
      const mapped = categories.map(c => c?.promo_type?.constant)
      return Array.from(new Set(mapped))
    },
    confirm () {
      if (this.selectedCategory?.id) {
        return this.$router.push({
          name: 'Promo',
          params: { 
            promo_category: this.selectedCategory,
            responsible_party_id: this.responsiblePartyId
          }
        })
      }
    }
  }
}
</script>